<template>
  <a-drawer width="35%" height="500px" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="公会编号" prop="guildNo" >
      {{form.guildNo}}
      </a-form-model-item>
      <a-form-model-item label="公会名称" prop="guildName" >
       {{form.guildName}}
      </a-form-model-item>
      <a-form-model-item label="公会id" prop="id" style="display: none;" >
        <a-input v-model="form.id" placeholder="请输入公会id" />
      </a-form-model-item>

      <a-form-model-item label="登录账号" prop="accountNo"  >
        <a-input v-model="form.accountNo" placeholder="请输入登录账号" />
      </a-form-model-item>
      <a-form-model-item label="默认密码" prop="password"  >
        <a-input v-model="form.password" placeholder="默认密码：123456" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
  import { addGuildAccount } from '@/api/biz/guild'

  export default {
    name: 'CreateGuildAcountForm',
    props: {
      applyStatusOptions: {
        type: Array,
        required: false
      }
    },
    components: {
    },
    data () {
      return {
        submitLoading: false,
        formTitle: '',
        // 表单参数
        form: {
          id: null,

          guildNo: null,

          guildName: null,

          accountNo: null,

          password: null,

        },
        // 1增加,2修改
        formType: 1,
        open: false,
        rules: {
          accountNo: [
            { required: true, message: '登录账号不能为空', trigger: 'blur' },
          ]
        }
      }
    },
    filters: {
    },
    created () {
    },
    computed: {
    },
    watch: {
    },
    mounted () {
    },
    methods: {
      onClose () {
        this.open = false
      },
      // 取消按钮
      cancel () {
        this.open = false
        this.reset()
      },
      // 表单重置
      reset () {
        this.formType = 1
        this.form = {
          id: null,
          guildNo: null,
          guildName: null,
          accountNo: null,

          password: null,
        }
        this.$nextTick(() => {
          if(this.$refs.form){
            this.$refs.form.resetFields()
          }
        })
      },
      /** 新增按钮操作 */
      handleAdd (row) {
        let that=this;
        that.reset()
        that.form.id=row.id;
        that.form.guildName=row.guildName;
        that.form.guildNo=row.guildNo;
        console.log(row);
        that.open = true
        that.formTitle = '创建公会账号'
      },

      /** 提交按钮 */
      submitForm: function () {
        let that=this;
        that.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true

              addGuildAccount(this.form).then(response => {
                this.$message.success('创建成功', 3 )
                this.open = false
                this.$emit('ok')

              }).finally(() => {
                this.submitLoading = false
              })

          } else {
            return false
          }
        })
      },


    }
  }
</script>
